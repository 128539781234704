import { ReactElement } from 'react'

import { Whitespace } from '~/components/atoms'
import { NewsletterForm } from '~/components/molecules'
import { PrivacyInfo } from '../molecules'

//----- Component -----//
export const NewsletterSignup = (): ReactElement => {
	return (
		<section>
			<Whitespace xs={'1em'} />
			<NewsletterForm signupSource="News" subscriptions={['DSC']} />
			<Whitespace xs={'1.5em'} />
			<PrivacyInfo />
		</section>
	)
}
