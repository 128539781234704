import { ReactElement } from 'react'

import { Container } from '~/components/atoms'
import { SubscribeTile, NewsletterTile } from '~/components/molecules'
import { TileBlock } from '~/components/organisms'

//----- Component -----//

export const MagazineBottom = (): ReactElement => (
	<Container>
		{/* Product Area/Recommendations disabled due to pointing at 3rd Party Store */}
		{/* <ProductArea products={props.products} noFooter /> */}
		<TileBlock>
			<NewsletterTile />
			<SubscribeTile />
		</TileBlock>
	</Container>
)
