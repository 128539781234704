import { ReactElement } from 'react'
import styled from 'styled-components'

import { Anchor } from '~/components/atoms'
import { SiteConfig } from '~/config'

//----- Styling -----//

const InfoWrap = styled.aside`
	font-size: 0.9rem;
`

const Title = styled.div`
	margin-bottom: 0;
	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	text-transform: uppercase;
`

const Content = styled.p`
	margin-top: 0.5em;
	color: ${(p) => p.theme.color.gray[46]};
`

const LinkList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;

	font-weight: ${(p) => p.theme.font.sans.weight.bold};

	& > li {
		display: inline;
	}

	& > li + li::before {
		content: '|';
		margin: 0 0.5em;
	}
`

const Link = styled(Anchor)`
	color: ${(p) => p.theme.color.gray[26]};
`

//----- Component -----//

export const PrivacyInfo = (): ReactElement => {
	const { newsletterChangeEmail, newsletterUnsubscribe, privacyPolicy } =
		SiteConfig.externalUrls

	return (
		<InfoWrap>
			<Title>Privacy Information</Title>
			<Content>
			Discover Magazine needs the contact information you provide to us to contact you about our products and services.
			You may unsubscribe from these communications at anytime.
			For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, check out our <Link to={privacyPolicy}>privacy policy</Link>.
			</Content>
			<LinkList>
				<li>
					<Link to={privacyPolicy}>View Our Privacy Policy</Link>
				</li>
				<li>
					<Link to={newsletterUnsubscribe}>Unsubscribe</Link>
				</li>
				<li>
					<Link to={newsletterChangeEmail}>Change Email Address</Link>
				</li>
			</LinkList>
		</InfoWrap>
	)
}
