import { ReactElement } from 'react'

import { BlockDescriptor, Container } from '~/components/atoms'
import { SubscribeTile, NewsletterTile } from '~/components/molecules'
import { ArticleGrid, TileBlock } from '~/components/organisms'
import type { ArticleSummaryData } from '~/types/content'

export const ArticleBottom = (props: {
	articles?: ArticleSummaryData[]
}): ReactElement => (
	<Container>
		<BlockDescriptor marginTop marginBottom>
			More From Discover
		</BlockDescriptor>
		<ArticleGrid articles={props.articles} wideThumbs />

		{/* Product Area/Recommendations disabled due to pointing at 3rd Party Store */}
		{/* <ProductArea products={props.products} noFooter /> */}

		<TileBlock>
			<NewsletterTile />
			<SubscribeTile />
		</TileBlock>
	</Container>
)
