import { ReactNode, ReactElement } from 'react'
import Head from 'next/head'
import styled, { css } from 'styled-components'

import { Backdrop, Container, OrganizationSchema } from '~/components/atoms'
import { AnnounceRibbon } from '~/components/molecules'
import {
	AccessCodeModal,
	Footer,
	Header,
	LoginModal,
	ReaderModal,
	RegisterModal,
	SlideoutNav
} from '~/components/organisms'
import { SiteConfig } from '~/config'
import { usePageContext, useUserContext } from '~/contexts'

//----- Definitions -----//

const COLUMN = {
	MARGIN: 32,
	WIDTH: 300
}

//----- Styling -----//

interface ColumnProps {
	hasLeft: boolean
	hasRight: boolean
	hasBoth: boolean
}

const ContentColumn = styled.div<ColumnProps>`
	flex-grow: 1;
	flex-shrink: 1;
	max-width: 100%;

	${(p) =>
		(p.hasLeft || p.hasRight) &&
		css`
			@media (min-width: ${COLUMN.WIDTH * (p.hasBoth ? 4 : 3)}px) {
				${p.hasLeft && `margin-left: ${COLUMN.MARGIN}px`};
				${p.hasRight && `margin-right: ${COLUMN.MARGIN}px`};
			}
		`}
`

const AsideColumn = styled.aside<{ hasBoth?: boolean }>`
	min-width: 300px;

	display: none;

	@media (min-width: ${(p) => COLUMN.WIDTH * (p.hasBoth ? 4 : 3)}px) {
		display: block;
	}
`

//----- Component -----//

interface TemplateProps {
	top?: ReactNode
	right?: ReactNode
	bottom?: ReactNode
	left?: ReactNode
	children: ReactNode
	hasSkimlinks?: boolean
	bodyEnd?: ReactNode
	useContainer?: boolean
}

export const StandardTemplate = (props: TemplateProps): ReactElement => {
	const {
		top,
		right,
		bottom,
		left,
		children,
		hasSkimlinks,
		bodyEnd,
		useContainer
	} = props

	const hasLeft = !!left
	const hasRight = !!right
	const hasBoth = hasLeft && hasRight

	const title = usePageContext().title
	const pageTitle = `${title ? title + ' | ' : ''}${SiteConfig.siteInfo.title}`

	const userCtx = useUserContext()
	const userType = userCtx.hasMagazineSub
		? 'subscriber'
		: userCtx.isLoggedIn
			? 'registered'
			: 'anonymous'

	const mainContent = (
		<div style={{ display: 'flex' }}>
			{hasLeft && <AsideColumn hasBoth={hasBoth}>{left}</AsideColumn>}
			<ContentColumn hasLeft={hasLeft} hasRight={hasRight} hasBoth={hasBoth}>
				{children}
			</ContentColumn>
			{hasRight && <AsideColumn hasBoth={hasBoth}>{right}</AsideColumn>}
		</div>
	)

	return (
		<>
			<Head>
				<title>{pageTitle}</title>
				<meta name="description" content={SiteConfig.siteInfo.description} />
				<meta name="user-type" content={userType} />
				<meta name="robots" content="max-image-preview:large"></meta>
			</Head>
			<OrganizationSchema />
			<Header />
			<AnnounceRibbon />

			{top}
			{useContainer ? <Container>{mainContent}</Container> : mainContent}
			{bottom}

			<Footer />
			<Backdrop />
			<SlideoutNav />
			<LoginModal />
			<ReaderModal />
			<RegisterModal />
			<AccessCodeModal />
			{hasSkimlinks && (
				<script
					type="text/javascript"
					src="https://s.skimresources.com/js/181780X1651258.skimlinks.js"
					defer
				></script>
			)}
			{bodyEnd}
		</>
	)
}
